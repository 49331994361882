<template>
    <div class="account-content">
        <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
            <div class="section-title margin-deliver">{{ $t("Change Password")}}</div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-8">
                <form action="">
                    <div class="billing-card form-box">
                        <div class="form-group">
                            <label for="new_password">{{ $t("New Password")}}*</label>
                            <input type="password" id="new_password" v-model="form.new_password" class="form-control" placeholder="********"/>
                            <div class="error-message" v-show="errors.new_password">{{ errors.new_password ? $t(errors.new_password.join(' ')) : '' }}</div>
                        </div>

                        <div class="form-group">
                            <label for="confirm_password">{{ $t("Confirm Password")}}*</label>
                            <input type="password" id="confirm_password" v-model="form.confirm_password" class="form-control" placeholder="********"/>
                            <div class="error-message" v-show="errors.confirm_password">{{ errors.confirm_password ? $t(errors.confirm_password.join(' ')) : '' }}</div>
                        </div>

                        <div class="form-group mt-4 text-right mb-0">
                            <submit-button :loading="isLoading" :click="updatePassword">{{ $t("Change Password")}}</submit-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import client from "../../app/api/Client";

    export default {
        data() {
            return {
                form: {
                    new_password: '',
                    confirm_password: '',
                },

                errors: [],
                isLoading: false,
            }
        },

        methods: {
            updatePassword() {
                try {
                    this.isLoading = true;
                    this.errors = [];
                    client().put(`/my-account/change-password`, this.form).then(({data}) => {
                        if (data.status === 'SUCCESS') {
                            this.$toast.success(this.$t(data.message));
                            this.form.new_password = '';
                            this.form.confirm_password = '';
                        } else {
                            this.$toast.error(this.$t(data.message));
                        }
                    }).catch(err => {
                        if (err.response.status === 422) {
                            this.errors = err.response.data.message;
                        } else {
                            this.$toast.error(this.$t(err.response.data.message));
                        }
                    }).finally(res => {
                        this.isLoading = false;
                    });
                } catch (e) {
                }
            }
        }
    }
</script>
